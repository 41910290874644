@import 'styles/_generic.scss';

.wrapper {
  display: flex;
  margin: auto;
  width: 100%;
  background-color: $grey;
  max-width: 1280px;
  margin-bottom: 0;
  overflow-y: hidden;
  @include mq-max(1300px) {
    margin-bottom: 0;
  }
}

@import 'src/styles/_generic.scss';

.wrapper {
  height: 100px;
  background-color: white;
  @include flex(row, nowrap, flex-start, center);
  width: 100%;
  border-bottom : 1px solid $delimiter;
  /* &:after {
    content: '';
    @include flex-grow(1);
    @include flex-order(2);
  } */
  // @include mq-max(1350px) {
  //   padding-left: 25px;
  // }
  @include down-md {
    align-items: center;
    @include flex-justify-content(space-between);
    padding-left: 12px;
    display: none;
    .tooltipText {
      display: none;
    }
  }
  @include down-xs {
    height: 70px;
  }
}

.logo {
  cursor: pointer;
  @include inline-flex(column, nowrap, center, flex-start);
  @include flex-order(1);
  margin-right: 42px;

  @include down-md {
    font-size: 0.8em;
    margin-left: 16px;
  }
  img {
    width: 120px;
  }
  span {
    margin-top: 6px;
    font-weight: 600;
    font-size: 0.84rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white;
    width: 100%;
    text-align: center;
    @include down-xs {
      margin-top: 6px;
    }
  }
}

.navLinks {
  height: 100%;
  @include inline-flex(row, nowrap, center, flex-end);
  @include flex-grow(1);
  @include flex-order(2);
  width: 100%;
  margin: auto;
  max-width: 1280px;
  background-color: #fff;
  gap: 2px;
  .linkWrapper {
    @include inline-flex(row, wrap, center, center, center);
    //height: 50px;
    height: 50px;
    padding: 16px 32px;
    width: auto;
    text-align: center;
    @include border-radius(5px 5px 0 0);
    @include transition(all 0.3s ease);
    //transition: all 0.3s ease;

    color: #000000;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    //text-decoration: underline;
    //text-decoration-color: transparent;
    //text-underline-offset: 0.3rem;
    letter-spacing: 2px;
    margin-bottom : -1px;
    border : 1px solid white;
    border-bottom : 1px solid $delimiter;

    &:hover,
    &.selected {
      //border-bottom: 1px solid $green;
      //color: $green;
      //text-decoration-color: $green;
      background-color: $grey;
      border : 1px solid $delimiter;
      border-bottom : 1px solid $grey;
    }

    &.selected{
      font-weight: 600;
    }

    &:hover{
    }
  }

  @include down-md {
    display: none;
  }
}

.profilSection {
  @include flex(column, nowrap, flex-end, flex-end);
  position: relative;
  height: 100%;
  width: 260px;
  padding-right: 30px;
  @include flex-order(3);
  @include down-md {
    @include flex-justify-content(center);
  }

  .profileIcon {
    @include flex(column, nowrap, center, center);
    height: 50px;
    position: relative;
    .tooltip {
      position: absolute;
      top: 0;
      right: -10px;
      fill: rgb(255, 0, 0);
    }
  }
  .profileSvg {
    width: 32px;
    height: 32px;
    @include down-sm {
      width: 42px;
      height: 42px;
    }

    &.green {
      fill: $green;
    }
  }
  .tooltip {
    background-color: white;
    @include border-radius(50%);
  }
}

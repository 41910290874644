$titre: 'Roboto', sans-serif;
$texte: 'Roboto', sans-serif;
$fontawesome: 'Font Awesome 5 Pro';
$fontawesomebrand: 'Font Awesome 5 Brands';

$delimiter: #e3e3e3;

$black: #000;
$green: #a8ce29;
$grey: #f4f4f4;
$grey2: #919191;
$grey3: #494949;
$blue: #17243f;
$error: #cb2346;
$valid: #62b762;
$warning: orange;

$bgerror: lighten($error, 50%);

//$maxwidth: 1480px;

$xxs: 420px;
$xs: 576px;
$sm: 750px; /* < MOBILE */
$md: 1024px; /* < TABLETTE PORTRAIT */
$lg: 1200px; /* < TABLETTE PAYSAGE > ORDINATEUR */
$xl: 1440px;
$xxl: 1650px;

@mixin custom-select(
  $class,
  $select-color: #fff,
  $select-background: #c0392b,
  $select-options-background: #fff,
  $select-options-color: #000,
  $select-width: 220px,
  $select-height: 40px
) {
  /*.select,*/
  .select.#{$class} {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: $select-color;
    width: $select-width;
    height: $select-height;
    @include unselected;

    .select-hidden {
      display: none;
      visibility: hidden;
      padding-right: 10px;
    }

    .select-styled {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $select-background;
      padding: 8px 15px;
      @include inline-flex(row, nowrap, space-around, center);
      //@include transition(all 0.2s ease-in);
      &:after {
        @include fawesome('\f0d7');
        font-size: 1rem;
      }

      &:hover {
        background-color: darken($select-background, 2);
      }

      &:active,
      &.active {
        background-color: darken($select-background, 5);

        &:after {
          content: '\f0d8';
        }
      }
    }

    .select-options {
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 999;
      margin: 0;
      padding: 0;
      list-style: none;
      background-color: $select-options-background;
      color: $select-options-color;

      li {
        margin: 0;
        padding: 12px 0;
        text-align: center;
        border-top: 1px solid darken($select-background, 10);
        //@include transition(all 0.15s ease-in);
        &:hover {
          color: $select-background;
          background: $select-color;
        }

        &[rel='hide'] {
          display: none;
        }
      }
    }
  }
}

@mixin transform($value...) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

@mixin arrow(
  $size: 15px,
  $hh: left,
  $sideh: 0px,
  $vv: top,
  $sidev: 0px,
  $color: black,
  $orient: right,
  $zone: before,
  $zindex: 10
) {
  position: relative;

  &:#{$zone} {
    $direction: left;

    @if $orient == top {
      $direction: bottom;
    } @else if $orient == right {
      $direction: left;
    } @else if $orient == bottom {
      $direction: top;
    } @else {
      $direction: right;
    }

    position: absolute;
    #{$hh}: $sideh;
    #{$vv}: $sidev;
    content: '';
    border-width: $size;
    border-color: transparent;
    border-style: solid;
    border-#{$direction}-color: $color;
    z-index: $zindex;
  }
}

@mixin carre($size, $radius: 0px) {
  width: $size;
  height: $size;
  text-align: center;
  line-height: $size;

  @if ($radius != 0px) {
    @include border-radius($radius);
  }
}

@mixin spin($delay: 2s) {
  animation: spin linear $delay;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: spin linear $delay;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: spin linear $delay;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: spin linear $delay;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: spin linear $delay;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

@mixin blur($i) {
  filter: blur($i);
  -webkit-filter: blur($i);
  -moz-filter: blur($i);
  -o-filter: blur($i);
  -ms-filter: blur($i);
}

@mixin scrollBar($color: #000000, $size: 5px, $background-color: #f5f5f5) {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: $background-color;
  }

  &::-webkit-scrollbar {
    width: $size;
    background-color: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
  }
}

@mixin fawesome($code, $type: solid, $family: '') {
  content: $code;

  @if ($family == 'brand') {
    font-family: 'Font Awesome 5 Brands';
  } @else {
    font-family: 'Font Awesome 5 Pro';
  }

  font-size: 0.7rem;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);

  @include fatype($type);
}

@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
  -moz-transform: scale($ratio);
  -ms-transform: scale($ratio);
  -o-transform: scale($ratio);
  transform: scale($ratio);
}

@mixin material($code) {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  vertical-align: middle;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  content: $code;
}

@mixin fatype($type: solid) {
  @if ($type == solid) {
    font-weight: 900;
  } @else if ($type == regular) {
    font-weight: 400;
  } @else if ($type == light) {
    font-weight: normal;
  }
}

@mixin ellipsisN($font-size, $line-height, $lines-to-show) {
  display: block;
  display: -webkit-box;
  height: #{$line-height * $lines-to-show + 0.1}px;
  font-size: #{$font-size}px;
  line-height: #{$line-height}px;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin block($w: 100%, $h: auto) {
  display: block;
  width: $w;
  @if ($h != auto) {
    height: $h;
    line-height: $h;
  }
}

@mixin inline($w: auto, $h: auto, $valign: middle) {
  display: inline-block;
  width: $w;
  height: $h;
  vertical-align: $valign;

  @if $h != 'auto' {
    line-height: $h;
  }
}

@mixin object-fit($type: cover) {
  object-fit: $type;
  -o-object-fit: $type;
}

@mixin aspect-ratio($width, $height, $contentclass: none) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc($height / $width) * 100%;
  }

  @if $contentclass!= 'none' {
    > .#{$contentclass} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

@mixin rotate($r) {
  -moz-transform: rotate($r);
  -webkit-transform: rotate($r);
  -o-transform: rotate($r);
  -ms-transform: rotate($r);
  transform: rotate($r);
}

@mixin opacity($value, $important: false) {
  @if $important == false {
    -moz-opacity: $value;
    -khtml-opacity: $value;
    -ms-filter: 'alpha(opacity=#{$value * 100})';
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$value * 100});
    filter: alpha(opacity=#{$value * 100});
    opacity: $value;
  } @else {
    -moz-opacity: $value !important;
    -khtml-opacity: $value !important;
    -ms-filter: 'alpha(opacity=#{$value * 100})' !important;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$value * 100}) !important;
    filter: alpha(opacity=#{$value * 100}) !important;
    opacity: $value !important;
  }

  zoom: 1;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin boxs {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin boxshadow($offsetx, $offsety, $blur, $color) {
  -webkit-box-shadow: $offsetx $offsety $blur 0px $color;
  box-shadow: $offsetx $offsety $blur 0px $color;
  -moz-box-shadow: $offsetx $offsety $blur 0px $color;
}

@mixin boxshadowinset($offsetx, $offsety, $blur, $color) {
  -webkit-box-shadow: inset $offsetx $offsety $blur 0px $color;
  box-shadow: inset $offsetx $offsety $blur 0px $color;
  -moz-box-shadow: inset $offsetx $offsety $blur 0px $color;
}

@mixin unselected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin clickable {
  @include unselected();
  cursor: pointer;
}

@mixin elips {
  overflow: hidden;
  -o-text-overflow: ellipsis; /* pour Opera 9 */
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transition($ctn...) {
  -webkit-transition: $ctn;
  -moz-transition: $ctn;
  -ms-transition: $ctn;
  -o-transition: $ctn;
  transition: $ctn;
}

@mixin transition-delay($delay) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin notransition {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

@mixin unskin {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include border-radius(0px);
}

@mixin flex-direction($direction, $important: false) {
  @if $important == false {
    -webkit-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
  } @else {
    -webkit-flex-direction: $direction !important;
    -ms-flex-direction: $direction !important;
    flex-direction: $direction !important;
  }
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-display($display) {
  @if $display== 'flex' {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  } @else if $display== 'flex !important' {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else if $display== 'inline-flex' {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
  } @else if $display== 'inline-flex !important' {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@mixin flex-justify-content($justify-content, $important: false) {
  @if $important == false {
    -webkit-justify-content: $justify-content;
    justify-content: $justify-content;

    @if $justify-content==flex-start {
      -ms-flex-pack: start;
    } @else if $justify-content==flex-end {
      -ms-flex-pack: end;
    } @else if $justify-content==center {
      -ms-flex-pack: center;
    } @else if $justify-content==space-between {
      -ms-flex-pack: justify;
    } @else if $justify-content==space-around {
      -ms-flex-pack: distribute;
    }
  } @else {
    -webkit-justify-content: $justify-content !important;
    justify-content: $justify-content !important;

    @if $justify-content==flex-start {
      -ms-flex-pack: start !important;
    } @else if $justify-content==flex-end {
      -ms-flex-pack: end !important;
    } @else if $justify-content==center {
      -ms-flex-pack: center !important;
    } @else if $justify-content==space-between {
      -ms-flex-pack: justify !important;
    } @else if $justify-content==space-around {
      -ms-flex-pack: distribute !important;
    }
  }
}

@mixin flex-align-items($align-items, $important: false) {
  @if $important == false {
    -webkit-align-items: $align-items;
    align-items: $align-items;

    @if $align-items==flex-start {
      -ms-flex-align: start;
    } @else if $align-items==flex-end {
      -ms-flex-align: end;
    } @else {
      -ms-flex-align: $align-items;
    }
  } @else {
    -webkit-align-items: $align-items !important;
    align-items: $align-items !important;

    @if $align-items==flex-start {
      -ms-flex-align: start !important;
    } @else if $align-items==flex-end {
      -ms-flex-align: end !important;
    } @else {
      -ms-flex-align: $align-items !important;
    }
  }
}

@mixin flex-align-content($align-content, $important: false) {
  @if $important == false {
    -webkit-align-content: $align-content;
    align-content: $align-content;

    @if $align-content==flex-start {
      -ms-flex-line-pack: start;
    } @else if $align-content==flex-end {
      -ms-flex-line-pack: end;
    } @else if $align-content==center {
      -ms-flex-line-pack: center;
    } @else if $align-content==space-between {
      -ms-flex-line-pack: justify;
    } @else if $align-content==space-around {
      -ms-flex-line-pack: distribute;
    } @else if $align-content==stretch {
      -ms-flex-line-pack: stretch;
    }
  } @else {
    -webkit-align-content: $align-content !important;
    align-content: $align-content !important;

    @if $align-content==flex-start {
      -ms-flex-line-pack: start !important;
    } @else if $align-content==flex-end {
      -ms-flex-line-pack: end !important;
    } @else if $align-content==center {
      -ms-flex-line-pack: center !important;
    } @else if $align-content==space-between {
      -ms-flex-line-pack: justify !important;
    } @else if $align-content==space-around {
      -ms-flex-line-pack: distribute !important;
    } @else if $align-content==stretch {
      -ms-flex-line-pack: stretch !important;
    }
  }
}

@mixin private-flex(
  $display: flex,
  $direction: row,
  $wrap: nowrap,
  $justify-content: flex-start,
  $align-items: flex-start,
  $align-content: flex-start
) {
  @include flex-display($display);

  @include flex-direction($direction);

  @include flex-wrap($wrap);

  @include flex-justify-content($justify-content);

  @include flex-align-items($align-items);

  @include flex-align-content($align-content);
}

@mixin flex(
  $direction: row,
  $wrap: nowrap,
  $justify-content: flex-start,
  $align-items: flex-start,
  $align-content: flex-start
) {
  @include private-flex(
    flex,
    $direction,
    $wrap,
    $justify-content,
    $align-items,
    $align-content
  );
}

@mixin inline-flex(
  $direction: row,
  $wrap: nowrap,
  $justify-content: flex-start,
  $align-items: flex-start,
  $align-content: flex-start
) {
  @include private-flex(
    inline-flex,
    $direction,
    $wrap,
    $justify-content,
    $align-items,
    $align-content
  );
}

@mixin flex-order($order) {
  -webkit-order: $order;
  -ms-flex-order: $order;
  order: $order;
}

@mixin flex-align-self($align-self) {
  -webkit-align-self: $align-self;
  -ms-flex-item-align: $align-self;
  align-self: $align-self;
}

@mixin flex-property($flex) {
  -webkit-flex: $flex;
  -ms-flex: $flex;
  flex: $flex;
}

@mixin flex-basis($w, $important: false) {
  @if $important == false {
    -webkit-flex-basis: $w;
    -moz-flex-basis: $w;
    flex-basis: $w;
  } @else {
    -webkit-flex-basis: $w !important;
    -moz-flex-basis: $w !important;
    flex-basis: $w !important;
  }
}

@mixin flex-grow($flex-grow, $important: false) {
  @if $important == false {
    -webkit-flex-grow: $flex-grow;
    -ms-flex-grow: $flex-grow;
    -moz-flex-grow: $flex-grow;
    flex-grow: $flex-grow;
  } @else {
    -webkit-flex-grow: $flex-grow !important;
    -ms-flex-grow: $flex-grow !important;
    -moz-flex-grow: $flex-grow !important;
    flex-grow: $flex-grow !important;
  }
}

@mixin flex-shrink($flex-shrink, $important: false) {
  @if $important == false {
    -webkit-flex-shrink: $flex-shrink;
    -ms-flex-shrink: $flex-shrink;
    -moz-flex-shrink: $flex-shrink;
    flex-shrink: $flex-shrink;
  } @else {
    -webkit-flex-shrink: $flex-shrink !important;
    -ms-flex-shrink: $flex-shrink !important;
    -moz-flex-shrink: $flex-shrink !important;
    flex-shrink: $flex-shrink !important;
  }
}

@mixin flex-item($order: 0, $flex: 0 1 auto, $align-self: auto) {
  @include flex-order($order);

  @include flex-property($flex);

  @include flex-align-self($align-self);
}

@mixin flex-list($sens: vertical) {
  @if $sens == vertical {
    @include flex(column, nowrap, flex-start, stretch);
  } @else {
    @include inline-flex(row, nowrap, space-between, center);
  }
}

@mixin up-xxs {
  @media (min-width: $xxs) {
    @content;
  }
}

@mixin up-xs {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin up-sm {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin up-md {
  @media (min-width: $md) {
    @content;
  }
}

@mixin up-lg {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin up-xl {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin down-xxs {
  @media (max-width:#{$xxs - 1px}) {
    @content;
  }
}

@mixin down-xs {
  @media (max-width:#{$xs - 1px}) {
    @content;
  }
}

@mixin down-sm {
  @media (max-width:#{$sm - 1px}) {
    @content;
  }
}

@mixin down-md {
  @media (max-width:#{$md - 1px}) {
    @content;
  }
}

@mixin down-lg {
  @media (max-width:#{$lg - 1px}) {
    @content;
  }
}

@mixin down-xl {
  @media (max-width:#{$xl - 1px}) {
    @content;
  }
}

@mixin down-xxl {
  @media (max-width:#{$xxl - 1px}) {
    @content;
  }
}

@mixin only-xxs {
  @include down-xxs {
    @content;
  }
}

@mixin only-xs {
  @include down-xs {
    @content;
  }
}

@mixin only-sm {
  @media (min-width:$xs) and (max-width:#{$sm - 1px}) {
    @content;
  }
}

@mixin only-md {
  @media (min-width:$sm) and (max-width:#{$md - 1px}) {
    @content;
  }
}

@mixin only-lg {
  @media (min-width:$md) and (max-width:#{$lg - 1px}) {
    @content;
  }
}

@mixin only-xl {
  @media (min-width:$lg) and (max-width:#{$xl - 1px}) {
    @content;
  }
}

@mixin mq-min($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin mq-max($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin mq($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

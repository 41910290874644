@import 'styles/_generic.scss';

.upperSection {
  @include inline-flex(row, nowrap, space-between, flex-start);
  width: 100%;
  gap: 18px;
  @include down-md {
    @include flex-direction(column);
    margin-bottom: 32px;
  }
  .wrapped {
    .lnkLike {
      color: $blue;
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .side {
    @include inline-flex(row, nowrap, flex-start, stretch);
    gap: 16px;
    @include mq-min(1290px) {
      padding-right: 32px;
    }
    @include down-sm {
      @include flex-wrap(wrap);
    }
    .access {
      @include inline-flex(column, nowrap, center, center, center);
      text-align: center;
      width: 90px;
      min-height: 90px;
      background-color: $blue;
      gap: 8px;
      @include border-radius(20px);

      &.devis,
      &.facture {
        svg {
          margin-right: -6px;
        }
      }

      &.publish {
        background-color: $green;
      }

      &:not(.disabled) {
        cursor: pointer;
        @include transition(opacity 0.34s ease-in);
        &:hover {
          @include opacity(0.9);
        }
      }

      &.disabled {
        background-color: #cdcdcd;
        cursor: default;
      }

      > span {
        color: #fff;
        font-size: 0.82rem;
      }
      svg {
        width: 26px;
        height: auto;
      }
    }
  }
}

.main {
  width: 100%;
  position: relative;
  .content {
    padding: 0 64px 64px 64px;
    width: 100%;
    @include down-md {
      padding: 0 16px 16px 16px;
    }
  }
}

.cardTitle {
  margin-bottom: 16px;
}

.container {
  @include inline-flex(row, wrap, flex-start, stretch);
  gap: 50px;
  padding-top: 64px;
  width: 100%;
  @include down-sm {
    padding-top: 0;
  }
}

.tuile {
  @include inline-flex(column, nowrap);
  width: 49%;
  width: calc(50% - 25px);

  @include down-md {
    width: 100%;
  }

  &.abonnement {
    .elts {
      @include flex(column, nowrap, flex-start);
      gap: 12px;
      &:empty {
        display: none;
      }

      + .renew,
      + .warning_abo {
        margin-top: 22px;
        + .warning_abo {
          margin-top: 16px;
        }
      }

      li {
        @include flex(column, nowrap, flex-start);
        padding-left: 52px;
        position: relative;
        gap: 4px;
        > span {
          &:first-of-type {
            position: relative;
            max-width: 240px;
            &:before {
              content: '';
              background-color: $black;
              width: 8px;
              aspect-ratio: 1 / 1;
              @include border-radius(50%);
              position: absolute;
              top: 0;
              left: -14px;
              @include transform(translateX(-100%) translateY(50%));
            }

            + span {
              font-weight: 600;
              font-size: 0.92rem;
            }
          }
        }
      }
    }

    > a {
      text-decoration: underline;
      font-size: 0.94rem;
      margin-top: 24px;
      margin-left: 28px;
      color: $blue;
      font-weight: 600;
    }

    .renew,
    .warning_abo {
      font-size: 0.94rem;
      + a {
        margin-left: 0;
      }
    }
  }

  &.avatar {
    .pic_row {
      @include inline-flex(row, nowrap, flex-start, center);
      gap: 32px;
      picture {
        width: 140px;
        @include flex-shrink(0);
        img {
        }
      }
      .speach {
        max-width: 200px;
        @include flex-grow(1);
        font-size: 0.82rem;
        line-height: 20px;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  &.passwordForm,
  &.coords {
    form {
      width: 100%;
      @include up-sm {
        max-width: 510px;
      }

      .toggleCollapse {
        text-decoration: underline;
        font-size: 0.88rem;
        color: $black;
        cursor: pointer;
        width: 100%;
        display: inline-block;
        padding: 0 4px;
        color: $blue;
        font-weight: 600;
        @include up-sm {
          margin-left: 156px;
        }
      }
    }

    .pwdStrength {
      padding-left: 156px;
      //display: none;
      margin-bottom: 0;
      padding-top: 0;
      margin-top: 0;
      @include transform(translateY(-10px));
      @include down-sm {
        padding-left: 0;
      }

      li {
        font-size: 0.64rem;
        width: 125px;
      }
    }

    .button {
      margin-left: 156px;
      margin-top: 22px;
      @include down-sm {
        margin-left: 0;
      }
    }
  }

  &.coords {
    .inputSociete {
      @include flex(row, nowrap, flex-start, center, flex-start);
      width: 100%;

      @include down-sm {
        @include flex(column, nowrap, flex-start, flex-start);
      }
      > label {
        width: 130px;
        @include flex-shrink(0);
      }

      input[type='text'] {
        min-width: inherit;
        @include flex-grow(1);
        width: 100%;
      }

      > div {
        @include inline-flex(row, nowrap, flex-start, center, flex-start);
        @include flex-grow(1);
        max-width: 374px;

        @include down-md {
          max-width: 100%;
        }
        @include down-sm {
          width: 100%;
        }

        > div {
          margin-bottom: 0;
        }
      }

      .inCreation {
        label {
          @include flex(row, nowrap, flex-start, center);
          width: 100%;
          margin: 0;
          position: relative;
          font-size: 0.9rem;
          color: $black;
          font-weight: 400;
          padding-right: 10px;
          @include unselected;
          white-space: nowrap;

          &:before {
            content: '';
            position: relative;
            border: 1px solid $black;
            height: 20px;
            width: 20px;
            @include boxs;
            margin-right: 6px;
          }
          &:after {
            content: '';
            position: absolute;
            background-color: $green;
            @include opacity(0);
            @include transition(opacity 0.34s ease-in);
            @include transform(translateX(-50%));
            top: 50%;
            left: 10px;
            height: 10px;
            width: 10px;
            @include transform(translateX(-50%) translateY(-50%));
          }
        }
        input {
          display: none;
          &:checked {
            & + label {
              &:after {
                @include opacity(1);
              }
            }
          }
        }
      }
    }

    .checkbox {
      margin-left: 158px;
      margin-top: 32px;
      @include down-sm {
        margin-left: 0;
      }

      label {
        @include flex(row, nowrap, flex-start, center);
        width: 100%;
        margin: 0;
        position: relative;
        font-size: 0.9rem;
        padding-bottom: 0;
        color: $blue;
        font-weight: 600;
        @include unselected;
        padding-left: 36px;

        @include down-sm {
        }
        &:before,
        &:after {
          content: '';
          border: 2px solid $blue;
          position: absolute;
          width: 24px;
          top: 50%;
          left: 0;
          @include boxs;
          @include transform(translateY(-50%));
          aspect-ratio: 1 / 1;
        }
        &:after {
          content: '';
          position: absolute;
          left: 5px;
          width: 14px;
          background-color: $green;
          border: none;
          @include opacity(0);
          @include transition(opacity 0.34s ease-in);
        }
      }
      input {
        display: none;
        &:checked {
          & + label {
            &:after {
              @include opacity(1);
            }
          }
        }
      }
    }
  }
}

.profilBanner {
  @include flex(column, nowrap, space-between, flex-start);
  width: 100%;
  margin-bottom: 0;
  margin-top: -20px;

  button {
    margin-top: 16px;
  }
}

.logoutWrapper {
  width: 100%;
  text-align: center;
  @include inline-flex(row, nowrap, center, center, center);
  margin-top: 22px;
  .logout {
    background-color: $blue;
  }
}

.collapse {
  overflow: hidden;
  @include transition(height 0.34s ease-in);
}

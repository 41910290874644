@import 'styles/_generic.scss';

.formContact {
  @include flex(column, nowrap, flex-start, center);
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 52px;
  left: 0;
  right: 0;
  padding: 32px;
  overflow: auto;
  //height: calc(100vh - 100px);
  z-index: 180;
  background-color: white;
  @include down-xs {
    top: 70px;
  }

  form {
    @include flex(column, nowrap, center, center);
    width: 100%;
    max-width: 450px;
  }

  .formTitle {
    font-size: 1.2rem;
    color: $black;
    font-weight: 600;
    margin-bottom: 12px;
    &.formTitleBis {
      margin-top: 22px;
      margin-bottom: 22px;
    }
  }

  .inputs_ctc {
    max-width: 450px;

    label {
      width: 38px;
      margin-left: -66px;
      @include down-sm {
        margin-left: 0;
      }

      & + * {
        @include flex-grow(1);
      }
    }
  }

  .input_textarea {
    width: 100%;
    //padding-left: 66px;
    textarea {
      width: 100%;
      height: 200px;
      min-height: 200px;
      resize: none;
      padding: 13px;
      margin-bottom: 22px;
      border: 1px solid #000;
    }
  }

  & > p {
    margin: 0 0 22px 0;
  }
  button {
    margin-bottom: 16px;
    height: 42px;
  }

  input[type='email'],
  input[type='text'] {
    max-width: 100%;
  }
  .close {
    @include inline-flex(row, nowrap, center, center);
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 20px;
    .close_text {
      font-size: 0.94rem;
      padding-right: 2px;
      color: $blue;
      font-weight: 600;
    }
    .closeModalButton {
      width: 32px;
      height: 32px;
      padding: 0;
      z-index: 200;
      color: $blue;
      &:hover {
        @include opacity(0.8);
        cursor: pointer;
      }
    }
  }
}

@import 'styles/_generic.scss';

.iconsContainer {
  @include inline-flex(column, nowrap, flex-start, flex-end);
  position: fixed;
  bottom: 70px;
  right: 16px;
  z-index: 900;
  @include transition(bottom, 0.3s);

  @include up-md {
    bottom: 16px;
    right: 24px;
  }

  &.bottom {
    bottom: 16px;
  }

  & > .formTrigger,
  & > button,
  & > a {
    margin-top: 12px;
    @include transition(all, 0.3s);
    color: white;
    @include carre(54px);

    svg {
      @include carre(26px);
    }

    span {
      color: white;
    }

    @include up-md {
      &:hover {
        width: 240px;
      }
    }
  }
}

.formTrigger {
  @include flex(row, nowrap, center, center);
  width: 42px;
  height: 42px;
  background-color: $blue;
  border-radius: 50%;
  border: 1px solid $green;
  transition: transform 0.2s;
  cursor: pointer;

  @include up-md {
    right: 30px;
    height: 64px;
    width: 64px;
    bottom: 30px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    @include object-fit(contain);
  }
}

.links {
  @include flex(row, nowrap, flex-start, stretch);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid $blue;
  height: 54px;
  z-index: 150;
  @include up-md {
    display: none;
  }

  .icon {
    height: 100%;
    border-left: 1px solid $blue;
    @include flex-grow(1);
    width: 20%;
    text-align: center;
    &:first-child {
      border-left: 0;
    }
    svg {
      font-size: 1.5rem;
      max-width: 100%;
    }
    a {
      display: flex;
      @include flex(column, nowrap, space-between, center);
      font-size: 0.7rem;
      padding: 8px 4px;
      height: 54px;
    }

    .active {
      background-color: $blue;
      color: white;
    }
  }
}

.triggerSvg {
  height: 20px;
  width: 20px;
  color: white;
  @include up-md {
    height: 30px;
    width: 30px;
  }
}

.smallIconText {
  color: white;
  font-size: 1.5rem;
}

@import 'styles/_generic.scss';

.wrapper {
  max-width: 100%;
  p {
    margin: 10px 0 0 0;
  }
  h1 {
    & + p {
      margin-top: 14px;

      a {
        font-weight: 700;

        &:hover {
          color: $green;
        }
      }
    }

    // span {
    //   &[class*='green'] {
    //     color: $green;
    //   }
    // }
  }
  button {
    display: flex;
    border: none;
    align-items: center;
    color: $green;
    svg {
      font-size: 1.5em;
    }
    p {
      margin: 0;
      margin-left: 1em;
    }
  }

  a[data-back] {
    @include inline-flex(row, nowrap, flex-start, center);
    font-size: 0.92rem;
    margin-top: 3px;
    color: $blue;
    font-weight: 600;
    svg {
      font-size: 1.24rem;
      padding-bottom: 1px;
    }
  }
}

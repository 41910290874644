@import 'styles/_generic.scss';

body,
html,
#root {
  min-height: 100%;
  width: 100%;
}

html {
  body {
    font-size: inherit;
    line-height: normal;
    color: $black;
    font-family: 'Roboto', sans-serif;
    background-color: $grey;
    overflow-x: hidden !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    * {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      outline: none;
    }

    div[id*='rainbow'] {
      header {
        background-color: white;
      }
    }
  }

  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        width: 270px;
        @include down-sm {
          @include opacity(0.5);
          @include transition(opacity, 0.3s);

          &.swiper-slide-active {
            @include opacity(1);
          }
        }
      }
    }
  }

  li {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }
  button {
    cursor: pointer;
  }
  img {
    vertical-align: top;
  }
  h1 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  textarea,
  input {
    font-family: inherit;
    font-size: inherit;
  }
  p {
    font-size: 0.9rem;
    line-height: 22px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

div[class*='popup-content'] {
  max-width: 100%;
}

/*les div qui commencent avec dt_rainbow et finissent avec modal*/
div[id^='dt_rainbow'][id$='modal'] {
  section {
    button[data-id='button-icon-element'] {
      //color : $blue;
      &:active,
      &:focus {
        box-shadow: none;
      }
    }

    label[for^='select'] + div {
      select {
        &:active,
        &:focus {
          box-shadow: none;
          //border : 1px solid $black;
        }
      }
    }
    table[aria-labelledby^='month-'] {
      button {
        &[data-selected='true'] {
          //background-color: $blue;
          &:active,
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

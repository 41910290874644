@import 'styles/_generic.scss';

.steps {
  @include inline-flex(row, nowrap, flex-start, center);
  padding: 0;
  margin: 0;

  @include down-xxs {
    @include flex-align-items(flex-end);
  }

  .step {
    margin: 0 16px;
    position: relative;

    @include down-xxs {
      text-align: center;
    }

    &:before {
      content: attr(data-num);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-size: 3rem;
      z-index: 0;
      color: $grey;
      font-weight: 700;

      @include down-xxs {
        transform: none;
        top: inherit;
        bottom: 12px;
        left: 50%;
        transform: translateY(0) translateX(-50%);
      }
    }

    span {
      position: relative;
      z-index: 1;
      color: $grey2;
      @include down-xxs {
        @include inline-flex(row, wrap, flex-start, flex-end, flex-end);
        text-align: center;
        font-size: 0.9rem;
        line-height: 16px;
      }
    }

    &.selected {
      &:before {
        color: $green;
      }

      span {
        font-weight: 700;
        color: $black;
      }
    }

    &.valid {
      span {
        color: $black;
      }

      &:before {
        color: $grey2;
      }
    }

    &.isClickable {
      cursor: pointer;
    }
  }
}

@import 'src/styles/_generic.scss';

%label {
  label {
    @include inline-flex(row, wrap, flex-end, center);
    width: 130px;
    text-align: right;
    margin-right: 28px;
    font-size: 0.9rem;
    @include down-sm {
      text-align: left;
      @include flex-justify-content(flex-start);
      margin-bottom: 8px;
    }
    @include down-xs {
      margin-right: 16px;
    }
  }
}
.input {
  @include flex(row, nowrap, flex-start, center);
  width: 100%;
  min-height: 48px;
  margin-bottom: 16px;

  @include down-sm {
    @include flex-direction(column);
    @include flex-align-items(flex-start);
  }

  @extend %label;
  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='tel'],
  input[type='email'],
  textarea {
    @include flex-align-self(stretch);
    @include inline-flex(row, nowrap, flex-start);
    align-items: center;
    padding: 4px 8px;
    @include flex-grow(1);
    max-width: 360px;
    border: 1px solid $black;

    @include down-sm {
      min-height: 48px;
      max-width: 100%;
    }

    @include down-xs {
      min-width: 1px;
      width: auto;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &.small_input {
    background-color: red;
    flex-grow: 0;
  }
  &.textarea {
    @include flex-align-items(flex-start);
    label {
      @include up-sm {
        padding-top: 12px;
      }
    }
    textarea {
      height: 250px;
      resize: none;
      // max-width: 360px;
      padding: 10px 8px;
    }
  }
  &.radio {
    @include flex-grow(0);
    label {
      //@include inline-flex(row, wrap, flex-end, center);
      @include flex-wrap(nowrap);
      @include flex-justify-content(flex-start);
      margin-left: 0;
      position: relative;
      width: auto;
      @include clickable;
      &:before {
        content: '';
        @include boxs;
        border: 1px solid $grey2;
        @include border-radius(50%);
        height: 24px;
        width: 24px;
        margin-right: 6px;
        @include flex-shrink(0);
      }
      &:after {
        content: '';
        position: absolute;
        @include boxs;
        top: 6px;
        left: 6px;
        height: 12px;
        width: 12px;
        background-color: $green;
        @include opacity(0);
        @include border-radius(50%);
        @include transition(opacity 0.34s ease-in);
      }
    }

    input {
      display: none;
      &:checked {
        & + label {
          &:after {
            @include opacity(1);
          }
        }
      }

      &:disabled {
        & + label {
          cursor: default;
          @include opacity(0.7);
        }
      }
    }
  }

  &.inputInsetLabel {
    position: relative;
    label {
      @include inline-flex(row, wrap, flex-start, center);
      text-align: left;
      width: auto;
      position: absolute;
      top: 50%;
      left: 4px;
      pointer-events: none;
      margin: 0;
      padding: 0 6px;
      max-width: calc(100% - 8px);
      @include transform(translateY(-50%));
      font-size: 0.9rem;
      @include transition(
        top 0.34s ease-out,
        font-size 0.34s ease-out,
        transform 0.34s ease-out
      );
      // @include down-sm {
      //   text-align: left;
      //   @include flex-justify-content(flex-start);
      //   margin-bottom: 0;
      // }
      // @include down-xs {
      //   margin-right: 0;
      // }
    }
    input[type='text'],
    input[type='password'],
    input[type='number'],
    input[type='tel'],
    input[type='email'] {
      @include transition(padding 0.34s ease-out);
    }
    &.focused {
      input[type='text'],
      input[type='password'],
      input[type='number'],
      input[type='tel'],
      input[type='email'] {
        padding: 14px 8px 2px 8px;
      }
      label {
        font-size: 0.68rem;
        top: 6px;
        @include transform(translateY(0));
      }
    }
  }
}

.inputsLine {
  @include flex(row, nowrap, flex-start, center);
  margin-bottom: 16px;
  width: 100%;
  & > .input,
  & > .inputsLike {
    @include flex-display(inline-flex);
    width: auto;
    @include flex-grow(1);
    text-align: center;
    margin-bottom: 0;
    &.radio {
      @include flex-grow(0);
    }
    & + .input {
      margin-left: 22px;
    }
  }
  & > label {
    display: inline-flex;
    flex-wrap: nowrap;
  }
  @extend %label;
}

.inputsLike {
  @extend .inputsLine;
  max-width: 360px;
  .input {
    flex-grow: 0;
  }
}

.radioContainer {
  @include flex(row, nowrap, flex-start, center);
  @include flex-grow(1);
  padding-left: 30px;
  & > div {
    margin: 0;
    max-width: 60px;
  }
}

.reactSelectAutoComplete {
  width: 100%;
  @include flex-align-self(stretch);
  @include inline-flex(row, nowrap, flex-start);
  align-items: center;
  @include flex-grow(1);
  max-width: 360px;

  @include down-sm {
    max-width: 100%;
    min-width: 1px;
    width: auto;

    input[type='text'] {
      min-height: inherit;
    }
  }

  & > div:first-of-type {
    width: 100%;
    border-radius: 0;
    min-height: 48px;
    border: 1px solid $black;
  }
}
.reactSelect {
  width: 360px;

  @include down-sm {
    width: 100%;
  }

  & > div[class$='-control'] {
    border: 1px solid black;
    min-height: 48px;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
  }
}

.reactMultiSelect {
  width: 100%;
  max-width: 100%;

  div[class='dropdown-container'] {
    width: 100%;
    @include flex-align-self(stretch);
    @include inline-flex(row, nowrap, flex-start);
    align-items: center;
    @include flex-grow(1);
    max-width: 360px;

    border: 1px solid black !important;
    min-height: 48px;
    border-radius: 0;
    box-shadow: none !important;

    div[class='dropdown-heading'] {
      height: 100%;
      font-size: 0.88rem;
    }
  }
}

.rainBowDatePicker {
  max-width: 200px;
  * {
    text-transform: initial !important;
  }
  input {
    border: 1px solid $black;
    height: 48px;
    line-height: 48px;
    padding: 4px 42px 4px 8px;
    font-size: 0.96rem;
    text-align: center;
    font-family: $texte;
    @include border-radius(0);
    &:focus,
    &:active {
      padding: 4px 42px 4px 8px;
      border: 1px solid $black;
      color: $black;
      @include boxshadow(0, 0, 0, #fff);
    }

    @include placeholder {
      font-weight: normal;
    }
  }
}

.error {
  & > input,
  label:before,
  .reactSelect > div[class$='-control'] {
    border: 1px solid red !important;
  }
  .reactSelectAutoComplete > div[class$='-control'] {
    border: 1px solid red !important;
  }
}

textarea {
  &.error {
    border: 1px solid red !important;
  }
}

@import 'styles/_generic.scss';

.container {
  @include flex(column, nowrap);

  .title_container {
    a {
      @include inline-flex(row, nowrap, flex-start, center);
      font-size: 0.92rem;
      margin-top: 4px;
      margin-left: 22px;
      color: $blue;
      font-weight: 600;
    }
    .title {
      padding-bottom: 0;
    }
  }

  .title {
    @include flex(row, wrap);
    width: 100%;
    margin: 0;
    position: relative;
    font-size: 1.25rem;
    padding-bottom: 12px;
    color: $blue;
    padding-left: 22px;
    @include down-xs {
      padding-left: 0;
    }
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      @include border-radius(50%);
      background-color: $green;
      position: absolute;
      top: 7px;
      left: 0;
      @include down-xs {
        display: none;
      }
    }
  }

  .content{
    @include flex(row,wrap);
    color: $black;
    font-size: 0.94rem;
    line-height: 22px;
    .text {
      margin: 0;
    }

    a{
      line-height: 22px;
      margin:0 3px;
      font-weight: 700;

      &:hover{
        color:$green;
      }
    }
  }
}

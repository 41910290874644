@import 'styles/_generic.scss';

.pwdStrength {
  @include inline-flex(row, wrap, flex-start);
  margin: 0;
  padding: 0;
  li {
    // @include flex-basis(50%);
    width: 138px;
    color: $black;
    font-size: 0.7rem;
    padding: 0 4px;

    svg {
      color: $black;
      margin-right: 4px;
    }

    &.valid {
      color: $valid;
      font-weight: 700;

      svg {
        color: $valid;
      }
    }
  }
}

@import 'src/styles/_generic.scss';

$base-color: #ddd;
$shine-color: #e8e8e8;
$size: 600px;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: $size;
}
@keyframes shine {
  0% {
    background-position: -$size;
  }

  100% {
    background-position: $size;
  }
}

.wrapper {
  @include background-gradient;
  animation: shine 3.8s infinite linear;
}

@import 'src/styles/_generic.scss';

.loginLogo {
  @include flex(column, nowrap, center, center);
  z-index: 10;
  margin: 0 auto 60px auto;
  text-align: left;
  width: 100%;
  max-width: 260px;
  @include down-xxs {
    margin-bottom: 42px;
    max-width: 260px;
  }
  h1 {
    color: white;
    font-weight: 100;
    font-size: 3rem;
  }
  img {
    width: 100%;
  }
  span {
    margin-top: 8px;
    font-weight: bold;
    color: white;
    font-weight: 600;
    font-size: 0.96rem;
  }
  // animation: slideDown 1s ease-out;
  // transform: translateY(0);
  // @keyframes slideDown {
  //   from {
  //     opacity: 0;
  //     transform: translateY(-10vh);
  //   }
  //   to {
  //     opacity: 1;
  //     transform: translateY(0);
  //   }
  // }
}

.loginWrapper {
  @include flex(row, wrap, center, center, center);
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: $blue;

  @media (max-height: 580px) {
    @include flex-align-content(flex-start);
  }

  .loginContainer {
    @include inline-flex(column, nowrap, center, center, center);
    width: 360px;
    max-width: 100%;
    padding: 36px 22px;
    position: relative;
    @include down-xxs {
      max-width: 320px;
    }
    &:after {
      content: '';
      background: url('/assets/login.svg') no-repeat;
      background-position: bottom 60% left 55%;
      background-size: 220px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: fadeIn 1s;
      @include opacity(0.5);

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 0.5;
        }
      }
    }

    form {
      @include flex(column, nowrap, flex-start);
      width: 100%;
      z-index: 10;
      text-align: left;

      .formDiv {
        @include flex(column, nowrap, flex-start, flex-start);
        width: 100%;
        transition: opacity 0.3s;

        label {
          color: white;
          margin-bottom: 16px;
        }
        input {
          margin-bottom: 32px;
          height: 48px;
          width: 320px;
          @include border-radius(5px);
          padding: 6px 24px;
          border: 1px solid white;
          background: $blue;
          color: $green;
          font-weight: 600;
          font-size: 0.94rem;
          max-width: 100%;
          text-align: left;
          @include down-md {
            text-align: left;
          }
          @include placeholder {
            text-align: left;
          }
        }

        &.hidden {
          @include opacity(0);
          pointer-events: none;
        }
      }

      p {
        color: white;

        &.forgottenPassword {
          color: $green;
          cursor: pointer;
          @include flex-align-self(center);
          margin-bottom: 0;
          @include down-xxs {
            margin-top: 0;
          }
        }
      }

      button {
        margin-top: 12px;
        width: 100%;

        max-width: 260px;
        @include flex-align-self(center);
      }
    }
  }
}

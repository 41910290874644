@import 'styles/_generic.scss';

.title {
  width: 100%;
  p {
    margin: 0;
    margin-bottom: 8px;
  }
  h1 {
    margin-bottom: 10px;
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  padding: 50px 64px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @include border-radius(20px);
  @include flex-align-self(center);
  @include down-md {
    flex-direction: column;
    align-items: center;
    padding: 32px 64px;
  }
  @include down-sm {
    padding: 32px 32px;
  }
  .logoSvg {
    width: 40%;
    margin-right: 42px;
    text-align: right;
    @include down-sm {
      width: 100%;
      margin-right: 0;
      text-align: center;
    }
    svg {
      width: 100%;
      height: auto;
      max-width: 250px;
    }
  }
}

.text {
  h3 {
    color: $blue;
    margin: 22px 0 10px 0;
  }
  button {
    margin-top: 10px;
    p {
      font-weight: bold;
    }
  }
  @include down-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
}

.skipStep {
  text-decoration: underline;
  margin-top: 10px;
  color: grey;
  cursor: pointer;
  font-size: 0.8rem;
}

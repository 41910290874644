@import 'styles/_generic.scss';

.button {
  @include flex(row, nowrap, center, center);
  @include border-radius(5px);
  border: none;
  min-width: 200px;
  padding: 16px 16px;
  color: white;
  white-space: nowrap;
  font-size: 0.9rem;
  background-color: $green;
  font-weight: bold;

  &.blueBg {
    background-color: $blue;
    color: #fff;
  }

  &.back {
    background-color: transparent;
    color: $grey2;
  }

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.9rem;
  }
  svg {
    width: 25px;
    height: auto;
  }
  & > * {
    & + * {
      margin-left: 12px;
    }
  }
}

.createButton {
  @extend .button;
  @include carre(64px, 50%);
  min-width: 0;
  font-size: 2rem;
  overflow: hidden;
  transition: width 0.2s, border-radius 0.2s;
  .iconHoverText {
    display: none;
    width: 0;
    opacity: 0;
    font-size: 1rem;
    transition: opacity 0.2s, width 0.2s, display 0.2s;
  }

  .iconInitialText {
    @include inline-flex(column, nowrap, center, center);
  }

  @include up-md {
    &:hover {
      width: 240px;
      @include border-radius(32px);
      .iconInitialText {
        display: none;
      }
      .iconHoverText {
        display: inline;
        opacity: 1;
        width: 100%;
      }
    }
  }

  @include down-md {
    &:hover {
      transform: scale(1.2);
    }
  }
}

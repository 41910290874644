@import 'src/styles/_generic.scss';

%navigate {
  position: absolute;
  content: '';
  display: inline-block;
  top: 0;
  height: 100%;
  width: 200px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
  @include down-xs {
    width: 100px;
  }
  pointer-events: none;
}
.swiper {
  width: 100%;
  position: relative;
  .slide {
    @include flex(column, nowrap, flex-start, flex-start);
    @include flex-align-self(stretch);
    height: auto;
    width: auto;

    &.slide_partenaire {
      max-width: 90%;
    }

    & > div {
      @include flex-grow(1);
      height: 100%;
    }


  }

  .prev,
  .next {
    width: 44px;
    height: 44px;
    background-color: $blue;
    @include border-radius(50%);
    text-align: center;
    pointer-events: auto;
    position: absolute;
    top: 135px;
    margin-top: -22px;
    z-index: 22;
    &:hover {
      cursor: pointer;
    }
    &:after {
      color: white;
      font-size: 1rem;
    }
    &[class~='swiper-button-disabled'],
    &[class~='swiper-button-lock'] {
      display: none;
    }
  }

  .prev {
    padding-right: 2px;
  }
  .next {
    padding-left: 2px;
  }

  &.hasHeader {
    .prev,
    .next {
      top: #{135px + 60px};
    }
  }
  /*&:before {
    @extend %navigate;
    content: '';
    left: 0;
  }
  &:after {
    @extend %navigate;
    right: 0;
    transform: rotate(180deg);
  }*/
}

.hasReachedBeginning {
  &:before {
    display: none;
  }
}

.hasReachedEnd {
  &:after {
    display: none;
  }
}

@import 'styles/_generic.scss';

.mainOver {
  padding-top: 42px;
}

.upperSection {
  text-align: center;
  margin-bottom: 32px;
}

.container {
  @include inline-flex(row, wrap, center, stretch);
  gap: 30px;
  padding-top: 32px;
  width: 100%;
  @include down-sm {
    padding-top: 0;
  }
}

.steps {
  width: 100%;
  @include flex-justify-content(center !important);
  @include down-sm {
    display: none;
  }
  li {
    cursor: default !important;
  }
}

.tuile {
  background-color: #fff;
  @include border-radius(30px);
  border : 1px solid $delimiter;
  padding: 52px;
  @include down-sm {
    padding: 32px 32px;
  }

  .title {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
  }

  .titleUser {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
  }

  p {
    color: #000;

    font-family: Roboto;
    font-size: 0.94rem;
    line-height: 22px;
    font-style: normal;
    font-weight: 400;
  }

  button,
  .button {
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 26px;
    color: white;
    width: auto;
    display: inline-flex;
  }

  &.service_client {
    width: 294px;
    padding-right: 36px;
    @include up-md {
      & ~ .actualite,
      & ~ .profil,
      & ~ .projet {
        width: calc(100% - #{294px + 30px});
      }
      & ~ .alerte {
        width: 100%;
      }
    }
    @include down-md {
      width: 100%;
      position: relative;
    }
    .descript {
      position: relative;
      padding-left: 36px;
      @include down-md {
        padding-left: 140px;
      }
      @include down-sm {
        padding-left: 0;
        @include inline-flex(row, nowrap, flex-start, center);
        gap: 22px;
      }
      .avatar {
        display: inline-block;
        width: 120px;
        overflow: hidden;
        aspect-ratio: 1 / 1;
        border: 6px solid white;
        @include border-radius(50%);
        filter: drop-shadow(-6px 6px 8px rgba(0, 0, 0, 0.12));
        position: absolute;
        top: 0;
        left: 0;
        @include transform(translateX(-100px));
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @include down-md {
          @include transform(none);
        }
        @include down-sm {
          position: relative;
          width: 80px;
          @include flex-shrink(0);
        }
        img {
          width: 100%;
          aspect-ratio: 1 / 1;
          @include object-fit;
        }
      }
    }

    .actions {
      @include inline-flex(column, nowrap, flex-start);
      width: 100%;
      @include down-md {
        padding-left: 140px;
        @include flex-direction(row);
        gap: 26px;
      }
      @include down-sm {
        padding-left: 0;
        @include flex-wrap(wrap);
        margin-top: 16px;
        @include flex-align-self(center);
      }
      @include down-sm {
        @include flex-justify-content(center);
      }
      > div {
        @include inline-flex(row, nowrap, flex-start, center);
        width: 100%;
        padding: 16px 0;
        border-top: 1px solid $grey;
        gap: 12px;
        cursor: pointer;
        &:hover {
          .ac_wrap {
            color: $green;
          }
        }
        @include down-md {
          border: none;
          width: auto;
        }
        @include down-sm {
          padding: 0;
        }
        // @include down-sm {
        //   border-top: 1px solid $grey;
        //   width: 100%;
        // }
        .ac_icon {
          @include inline-flex(row, nowrap, center, center);
          background-color: $green;
          width: 40px;
          aspect-ratio: 1 / 1;
          height: auto;
          @include flex-shrink(0);
          @include border-radius(50%);
          svg {
            display: inline-block;
            color: white;
            fill: white;
            width: 22px;
          }
        }

        .ac_wrap {
          @include inline-flex(column, nowrap, flex-start);
          font-size: 1rem;
          line-height: normal;
          letter-spacing: 0.01rem;
          font-weight: 600;
          color: $black;
          @include transition(color 0.34s ease-in);
          small {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: normal;
            + span {
              font-size: 1.125rem;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }

  &.actualite {
    position: relative;

    & + .abonnement {
      width: 100%;
    }

    @include down-xs {
      @include inline-flex(column, nowrap, flex-start);
      width: 100%;
    }

    .wrapped {
      padding-right: 180px;
      @include down-sm {
        padding-right: 0;
        width: calc(100% - 260px);
        display: inline-block;
        vertical-align: top;
        margin-right: 24px;
      }
      @include down-xs {
        width: 100%;
        @include flex-order(2);
      }
    }
    .entete {
      color: #000;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @include down-xs {
        font-size: 1.2rem;
      }
    }

    picture {
      display: inline-block;
      box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.12);
      width: 235px;
      height: 350px;
      position: absolute;
      top: 0;
      right: 0;
      @include border-radius(30px);
      overflow: hidden;
      @include transform(translateX(52px) translateY(-26px));
      @include transition(box-shadow 0.12s ease-in, transform 0.12s ease-in);
      &:hover {
        cursor: pointer;
        img {
          @include scale(1.05);
        }
      }

      &:active {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.12);
        @include transform(translateX(54px) translateY(-24px));
      }

      @include down-md {
        @include transform(translateX(14px) translateY(-26px));
        &:active {
          @include transform(translateX(16px) translateY(-24px));
        }
      }

      @include down-sm {
        width: 100%;
        height: auto;
        max-width: 235px;
        aspect-ratio: 235 / 350;
        @include transform(none);
        position: relative;
        &:active {
          @include transform(none);
        }
      }
      @include down-xs {
        max-width: 135px;
        margin-bottom: 32px;
        max-width: 100%;
        height: 130px;
        @include flex-order(1);
        @include border-radius(20px);
      }
      img {
        width: 100%;
        height: 100%;
        @include object-fit;
        @include transition(transform 0.34s ease-in);
      }
    }
  }
  &.vitrine,
  &.stripPublish {
    width: calc(100% - #{300px + 30px});
    @include down-sm {
      width: 100%;
    }

    .title {
      @include inline-flex(row, nowrap, flex-start, center);
      @include transform(translateY(-8px));
      @include down-sm {
        margin-top: 22px;
      }
      .ico {
        @include flex(row, wrap, center, center, center);
        height: 19px;

        margin-left: 14px;
        border: 4px solid white;
        background-color: white;
        position: relative;
        z-index: 2;
        border: 1px solid $grey;
        @include flex-align-self(center);
        @include border-radius(5px);

        picture {
          width: 65px;
          height: auto;
          vertical-align: top;
          @include border-radius(5px);

          img {
            width: 100%;
            height: 100%;
            @include border-radius(5px);
          }
        }
      }
    }

    .wrapped {
      position: relative;
      @include down-md {
        @include inline-flex(column, nowrap, flex-start, flex-start);
        width: 100%;
      }
      .innerWrapped {
        padding-left: 260px;
        @include down-md {
          padding-left: 0;
        }
      }
      picture {
        display: inline-block;
        box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.12);
        width: 314px;
        height: 168px;
        position: absolute;
        top: 0;
        left: 0;
        @include border-radius(30px);
        overflow: hidden;
        border: 6px solid white;
        //filter: drop-shadow(-6px 6px 8px rgba(0, 0, 0, 0.12));

        @include transform(translateX(-96px) translateY(-4px));

        @include down-md {
          position: relative;
          @include transform(none);
          @include flex-order(-1);
          border: none;
          margin-top: 32px;
          margin-bottom: 22px;
          width: 100%;
          max-width: 314px;
          height: auto;
          aspect-ratio: 314 / 168;
        }
        img {
          width: 100%;
          height: 100%;
          @include object-fit;
        }
      }
    }
  }
  &.abonnement {
    @include inline-flex(column);
    width: 300px;
    @include down-sm {
      width: 100%;
    }
    p {
      @include flex-grow(1);
      margin-top: 0;
    }
    button {
      @include flex-align-self(flex-end);
    }
  }

  &.profil {
    @include up-md {
      & ~ .projet {
        width: 438px;
      }
      & ~ .alerte {
        width: calc(100% - #{438px + 30px});
      }
    }

    .text {
      @include flex(row, nowrap, flex-start, center);
      gap: 72px;
      svg {
        width: 140px;
        @include flex-shrink(0);
        @include down-sm {
          display: none;
        }
      }
    }
  }

  &.projet,
  &.alerte,
  &.favori,
  &.contact {
    .no_result {
      color: #cacaca;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 100%;
      text-align: center;
      padding: 80px 0;

      &.no_result_smaller {
        font-size: 1.15rem;
        text-align: left;
        padding: 32px 0;
      }
    }
  }

  &.projet,
  &.alerte {
    width: 100%;
    .tuile_inlinelnk {
      @include flex(row, nowrap, flex-start, center);
      width: 100%;
      padding: 18px 0;
      border-bottom: 2px solid $grey;
      .gr {
        @include flex-grow(1);
        @include inline-flex(column, nowrap);
        gap: 6px;
      }
      .name {
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .surf {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .creation {
        font-size: 0.68rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #a3a3a3;
      }
      .ico {
        @include inline-flex(row, nowrap, center, center);
        background-color: $green;
        @include flex-shrink(0);
        width: 40px;
        aspect-ratio: 1 / 1;
        text-align: center;
        @include border-radius(50%);
        margin-left: 32px;
        svg {
          color: white;
          fill: #fff;
          width: 36px;
        }
      }

      + button {
        margin-top: 62px;
        @include up-lg {
          margin-top: 80px;
        }
      }
    }
  }
  &.contact,
  &.favori {
    width: 100%;
    overflow: hidden;

    p {
      > a {
        text-decoration: underline;
      }
      & + div[class*='swiper'] {
        margin-top: 32px;
      }
    }
  }
}

[class*='fancybox__container'] {
  [class*='fancybox__content'] {
    @include border-radius(30px);
    width: 740px;
    padding: 0;
    max-width: 94%;
    max-width: calc(100% - 94px);
    height: auto;
    max-height: inherit;
    margin-top: 30px;
    margin-bottom: 60px;
    @include down-sm {
      max-width: 100%;
      margin: 0;
      @include border-radius(0);
    }
  }
  [class*='fActu'] {
    @include inline-flex(column, nowrap, center, center);
  }

  [class*='fancybox__slide'] {
    @include down-sm {
      padding-top: 0 !important;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  [class*='fancybox__backdrop'] {
    background-color: #17243fe5;
  }

  [data-fancybox-close] {
    opacity: 1 !important;
    @include down-sm {
      top: 5px !important;
      right: 5px !important;
      @include border-radius(50%);
      background-color: rgba(#000, 0.7);
    }
    svg {
      color: white;
      stroke: white;
      @include opacity(1);
      @include down-sm {
        width: 18px;
      }
      path {
        color: white;
        fill: white;
        stroke: white;
      }
    }
  }

  [class='fEntete'] {
    @include inline-flex(column, nowrap, center, center);
    width: 100%;
    color: #fff;
    position: relative;
    padding: 80px 32px 140px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include border-radius(30px 30px 0 0);
    overflow: hidden;
    @include down-sm {
      padding: 50px 22px 120px;
      @include border-radius(0);
    }
    @include down-xs {
      padding: 50px 22px 110px;
      @include border-radius(0);
    }
    > * {
      z-index: 2;
      position: relative;
      max-width: 530px;
      width: 100%;
    }
    &:before {
      content: '';
      background-color: rgba(#000, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      max-width: 100%;
    }
  }

  [class='fTitle'] {
    font-size: 1.35rem;
    font-style: normal;
    font-weight: 600;
    @include down-xs {
      font-size: 1.125rem;
    }

    + p {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
    }
  }

  [class='fContent'] {
    @include inline-flex(column, nowrap, center, center);
    max-width: 640px;
    width: 100%;
    color: $black;
    @include flex-align-self(center);
    position: relative;
    z-index: 4;
    padding: 0 32px 120px;
    @include down-sm {
      padding: 0 22px 42px;
    }
    picture {
      display: inline-block;
      width: 100%;
      @include border-radius(10px);
      border: 3px solid #fff;
      margin-top: -80px;
      margin-bottom: 20px;
      overflow: hidden;
      @include down-sm {
        margin-bottom: 20px;
      }
      @include down-xs {
        margin-bottom: 10px;
      }
      img {
        display: inline-block;
        width: 100%;
      }
    }
  }

  [class='fText'] {
    color: $black;
    h2 {
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      ~ h2 {
        margin-top: 32px;
      }
    }
    p {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }

    a {
      color: $green;
      text-decoration: underline;
    }
  }
}

@import 'styles/_generic.scss';

.wrapper {
  background-color: $grey;
  min-height: calc(100vh - 115px);
  min-height: 110vh;
  width: 100%;
  padding: 64px;
  @include down-md {
    padding: 32px;
    width: 100%;
  }
  @include down-sm {
    padding: 16px;
  }
}

@import 'styles/_generic.scss';

.wrapper {
  .menuBars {
    width: 54px;
    color: white;
    fill: white;
    padding: 14px 16px;
    cursor: pointer;
  }
}

.linkWrapper{
  &.selected{
    color: $green !important;
  }
}

body{
  header{
    .liLogout{
      .logout{
        line-height: 18px !important;
        padding : 6px 0 !important;
        height : 30px !important;
        vertical-align: text-bottom;
        svg{
          display: inline-block;
          vertical-align: middle;
          line-height: 14px !important;
          margin-bottom : 1px;
        }
      }
    }
    
  }
}

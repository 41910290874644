@import 'styles/_generic.scss';

.loaderCard {
  @include flex(column, nowrap, center, center);
  width: 100%;
  height: auto;
  min-height: 120px;
  svg {
    fill: $green;
    height: 10px;
  }
}
